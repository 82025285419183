module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-remark-autolink-headers@6.13.1_gatsby@5.13.3_react-dom@18.2.0_react@18.2.0/node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"offsetY":64,"icon":"<svg aria-hidden=\"true\" height=\"20\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"20\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\" fill=\"currentColor\"></path></svg>","className":"header-link","maintainCase":false},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-remark-images@7.13.1_gatsby-plugin-sharp@5.13.1_gatsby@5.13.3/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":640,"linkImagesToOriginal":false,"showCaptions":true,"withWebp":true,"withAvif":true,"backgroundColor":"transparent","markdownCaptions":false,"quality":50,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-catch-links@5.13.1_gatsby@5.13.3/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@5.13.1_gatsby@5.13.3_graphql@16.8.1/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Jonas Lekevicius","short_name":"Lekevicius","start_url":"/","background_color":"#f3f3f3","theme_color":"#808080","description":"I design and build digital things","display":"standalone","icons":[{"src":"favicon.png","sizes":"16x16","type":"image/png"},{"src":"favicon@2x.png","sizes":"32x32","type":"image/png"},{"src":"icons/icon-48x48.png","sizes":"48x48","type":"image/png"},{"src":"icons/icon-72x72.png","sizes":"72x72","type":"image/png"},{"src":"icons/icon-96x96.png","sizes":"96x96","type":"image/png"},{"src":"icons/icon-144x144.png","sizes":"144x144","type":"image/png"},{"src":"icons/icon-192x192.png","sizes":"192x192","type":"image/png"},{"src":"icons/icon-256x256.png","sizes":"256x256","type":"image/png"},{"src":"icons/icon-384x384.png","sizes":"384x384","type":"image/png"},{"src":"icons/icon-512x512.png","sizes":"512x512","type":"image/png"}],"icon_options":{"purpose":"any maskable"},"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.13.3_babel-eslint@10.1.0_react-dom@18.2.0_react@18.2.0_typescript@5.4.2/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
